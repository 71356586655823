import { User } from 'common-ui';

import { getDifferenceInTime } from 'src/helpers/datetime';

import { GameInstance } from 'src/types/dao/game.types';

export type GameCellData = {
  id?: string;
  gamePlayName: string | null;
  playerName: string | null;
  playerEmail?: string | null;
  totalPlayTime: string | null;
  startGameTime: string | null;
};

export const setGameTableData = (
  games: GameInstance[],
  users: Record<string, User>,
  isRunningGame = false,
): GameCellData[] => {
  return games.map((gameInstance) => {
    const agentNames = gameInstance.agent_names || []; // assuming agent_names is available
    const gamePlayName = (() => {
      if (agentNames.length > 0) {
        if (agentNames.length === 1) {
          return agentNames[0];
        }
        return `${agentNames[0]} +${agentNames.length - 1}`;
      }
      return '?';
    })();

    return {
      id: gameInstance.id,
      gamePlayName,
      playerName: users[gameInstance.initiator_id]?.name || null,
      playerEmail: users[gameInstance.initiator_id]?.email || null,
      totalPlayTime: getDifferenceInTime(
        new Date(gameInstance.start_time),
        isRunningGame ? new Date() : new Date(gameInstance.end_time),
      ),
      startGameTime: gameInstance.start_time
        ? new Date(gameInstance.start_time).toLocaleString()
        : null,
      isRunningGame: isRunningGame,
    };
  });
};
