import classNames from 'classnames';
import { FC } from 'react';

import Button from 'src/components/Buttons/Button';
import NumberField from 'src/components/Inputs/NumberField';
import Loader from 'src/components/Loader';

import useTexts from 'src/hooks/useTexts';

import styles from './styles.module.scss';

export type Props = {
  userId: string;
  currentMinutes?: number;
  addMinutesToUser: ({ minutes }: { minutes: number; userId: string }) => void;
  isAddMinutesLoading?: boolean;
  addedMinutes: number;
  setAddedMinutes: (value: number) => void;
};

const AddMinutesToUser: FC<Props> = ({
  userId,
  currentMinutes,
  addMinutesToUser,
  isAddMinutesLoading,
  addedMinutes,
  setAddedMinutes,
}) => {
  const { t } = useTexts();
  return (
    <div className={styles['content-info']}>
      <div className={styles['content-info_wrap']}>
        <div className={styles['info-row']}>
          {t('invitation.minutes')}: {currentMinutes || 0}
        </div>
        <div className={styles['info-row']}>
          <NumberField
            value={addedMinutes}
            onChange={(value) => setAddedMinutes(value)}
          />

          <Button
            id='confirm-btn'
            color='success'
            variant='contained'
            onClick={() =>
              addMinutesToUser({
                userId,
                minutes: addedMinutes,
              })
            }
            className={classNames(styles['button'])}
          >
            <span>{t('form.add')}</span>
          </Button>
        </div>

        {isAddMinutesLoading && <Loader absolute />}
      </div>
    </div>
  );
};

export default AddMinutesToUser;
