import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { GetGroupAgentsResponse } from 'src/types/api/agent.types';
import {
  BaseUseApiProps,
  GetAgentSuccessResponse,
} from 'src/types/api/api.types';

import {
  makeApiArchiveAgent,
  makeApiBulkCreateAgents,
  makeApiCopyAgent,
  makeApiCreateAgent,
  makeApiUpdateAgent,
  makeApiUpdateAgentStatus,
} from '../functions/makeApiManage';

export const useApiCreateAgent = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetAgentSuccessResponse> = {}) => {
  const doRequest = useMemo(() => makeApiCreateAgent(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
export const useApiBulkCreateAgents = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetGroupAgentsResponse> = {}) => {
  const doRequest = useMemo(() => makeApiBulkCreateAgents(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiUpdateAgent = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiUpdateAgent(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiUpdateAgentStatus = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiUpdateAgentStatus(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiCopyAgent = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiCopyAgent(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiArchiveAgent = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiArchiveAgent(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
