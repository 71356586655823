import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { Checkbox } from '@mui/material';
import React from 'react';

import IconButton from 'src/components/Buttons/IconButton';
import DotsActions from 'src/components/TableList/components/DotsActions';

import { GameCellData } from 'src/helpers/games';

import { GameTableActionConfig } from './index';
import styles from './styles.module.scss';

export const renderGameCell = (
  gameData: GameCellData,
  tableActionConfig?: GameTableActionConfig,
  selectedInstances?: Array<string>,
  setSelectedInstances?: React.Dispatch<React.SetStateAction<Array<string>>>,
) => {
  const isChecked = selectedInstances?.includes(gameData.id || '');
  const handleCheckboxChange = () => {
    console.log(gameData);
    setSelectedInstances?.((prev) => {
      const newArray = [...prev];
      const index = newArray.indexOf(gameData.id || '');
      if (index > -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(gameData.id || '');
      }
      return newArray;
    });
  };

  return {
    select: <Checkbox checked={isChecked} onClick={handleCheckboxChange} />,
    gamePlayName: gameData.gamePlayName || 'N/A',
    playerName: gameData.playerName || 'N/A',
    totalPlayTime: gameData.totalPlayTime ? gameData.totalPlayTime : 'N/A',
    startGameTime: gameData.startGameTime ? gameData.startGameTime : 'N/A',
    stopGame: (
      <div>
        <IconButton
          tooltip={tableActionConfig?.t('form.stop')}
          className={styles['action']}
          onClick={() => tableActionConfig?.stopGame?.(gameData.id || '')}
        >
          <CloseRoundedIcon />
        </IconButton>
      </div>
    ),
    copyGameId: (
      <div className={styles['action-item']}>
        <IconButton
          tooltip={tableActionConfig?.t('game.copy_id')}
          className={styles['action']}
          onClick={() =>
            tableActionConfig?.copyGameInstanceId(gameData.id || '')
          }
        >
          <ContentCopyIcon />
        </IconButton>
      </div>
    ),
    actions: (
      <DotsActions>
        {tableActionConfig?.redirectToGame && (
          <div className={styles['action-item']}>
            <IconButton
              tooltip={tableActionConfig.t('form.start')}
              className={styles['action']}
              onClick={() =>
                tableActionConfig.redirectToGame(gameData.id || '')
              }
            >
              <LaunchRoundedIcon />
            </IconButton>
          </div>
        )}
        {tableActionConfig?.downloadInstanceLog && (
          <div className={styles['action-item']}>
            <IconButton
              tooltip={tableActionConfig.t('game.download_log')}
              className={styles['action']}
              onClick={() =>
                tableActionConfig.downloadInstanceLog(gameData.id || '')
              }
            >
              <CloudDownloadRoundedIcon />
            </IconButton>
          </div>
        )}
      </DotsActions>
    ),
  };
};

export const tableHeaders = [
  'Select',
  'Game Play Name',
  'Player Name',
  'Total Play Time',
  'Start Game Time',
  'Stop Game',
  'Copy Game ID',
  'Actions',
];
