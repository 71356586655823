import { AllFilter } from 'common-ui';
import { UserTier, UserTierFilter } from 'common-ui';
import { FC, useMemo } from 'react';

import Dropdown, { DropdownArrayItem } from 'src/components/Dropdown';
import NumberField from 'src/components/Inputs/NumberField';
import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import MultiAutoComplete from 'src/components/MultiAutoComplete';
import NothingToShow from 'src/components/NothingToShow';
import Search from 'src/components/Search';
import Select from 'src/components/Select';

import CheckoutButton from 'src/features/components/payment/CheckoutButton';
import UsersList from 'src/features/components/user/UsersList';

import useTexts from 'src/hooks/useTexts';

import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

const Users: FC = () => {
  const {
    search,
    setSearch,
    searchOpen,
    setSearchOpen,
    isLoading,
    user,
    handleSendInvitationsClick,
    accessTierFilter,
    setAccessTierFilter,
    usersToShow,
    invitationsModalOpen,
    setInvitationsModalOpen,
    handleSendInvitations,
    isSendingInvitationsLoading,
    invitationsFormData,
    invitationsFormDataSetters,
    adminUsers,
    loadNext,
    loadPrev,
    pageNumber,
    canLoadNext,
    canLoadPrev,
    navigateToUser,
  } = useComponentProps();
  const { t } = useTexts();

  const roleOptions = [
    ...Object.values(AllFilter),
    ...Object.values(UserTier),
  ].map((key) => ({
    value: key,
    label: t('user.' + key),
  }));

  const batchActionsDropdownContent = useMemo<Array<DropdownArrayItem>>(
    () => [
      {
        label: <div>{t('invitation.send_invitations')}</div>,
        callback: handleSendInvitationsClick,
      },
    ],
    [t, handleSendInvitationsClick],
  );

  const paginationConfig = {
    loadNext,
    loadPrev,
    pageNumber,
    canLoadNext,
    canLoadPrev,
  };

  return (
    <>
      <div className={styles['page']}>
        <div className={styles['info-group']}>
          <div className={styles['info-search']}>
            <Search
              value={search}
              onChange={setSearch}
              searchOpen={searchOpen}
              setSearchOpen={setSearchOpen}
              iconClassName={styles['info-search-icon']}
            />
          </div>
          <div className={styles['info-title-container']}>
            <h2>{t('user.users')}:</h2>
            <Dropdown list={batchActionsDropdownContent}>
              {t('user.batch_actions')}
            </Dropdown>
          </div>
          <div className={styles['filter-wrapper']}>
            <p>{t('general.filter_by')}:</p>
            <Select
              label={t('user.access_tier')}
              options={roleOptions}
              value={accessTierFilter}
              onSelect={(value: string) =>
                setAccessTierFilter(value as UserTierFilter)
              }
              theme='white'
              size='small'
            />
          </div>
          <>
            {searchOpen && (
              <h2 className={styles['group-name']}>
                {t('general.search_result')}:
              </h2>
            )}
            {usersToShow.length ? (
              <UsersList
                data={usersToShow}
                isLoading={isLoading}
                paginationConfig={paginationConfig}
                navigateToUser={navigateToUser}
              />
            ) : (
              <NothingToShow className={styles['nothing-to-show']} />
            )}
          </>
        </div>
      </div>
      {invitationsModalOpen &&
        (!user ? (
          <Loader fullPage />
        ) : (
          <ConfirmModal
            setOpen={setInvitationsModalOpen}
            title={t('invitation.send_invitation_codes_to_all_users')}
            onConfirm={handleSendInvitations}
            confirmButtonLabel={t('form.send')}
            isConfirmLoading={isSendingInvitationsLoading}
            size='sm'
          >
            <div>
              <p className={styles['remaining-play-minutes']}>
                {t('invitation.remaining_play_minutes')}: {user.minutes}
              </p>
              <p className={styles['remaining-play-minutes-1']}>
                {t('invitation.after_sending_invitations')}:{' '}
                {user.minutes -
                  invitationsFormData.minutes *
                    invitationsFormData.emails.length}
              </p>
              {!user.minutes && (
                <CheckoutButton wrapperClassName={styles['checkout-btn']} />
              )}
              <MultiAutoComplete
                className={styles['select-users']}
                options={adminUsers?.data.map((user) => user.email) || []}
                onChange={invitationsFormDataSetters.emails}
                label={t('user.users')}
                placeHolder={t('user.select_users')}
                theme='black'
              />
              <NumberField
                value={invitationsFormData.minutes}
                onChange={invitationsFormDataSetters.minutes}
                wrapperClassName={styles['minutes-input']}
                label={t('invitation.play_minutes')}
                theme='black'
              />
            </div>
          </ConfirmModal>
        ))}
    </>
  );
};

export default Users;
