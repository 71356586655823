import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

import Accordion from 'src/components/Accordion';
import Button from 'src/components/Buttons/Button';
import IconButton from 'src/components/Buttons/IconButton';
import TextField from 'src/components/Inputs/TextField';
import UploadImageVideoField from 'src/components/Inputs/UploadImageVideoField';
import Select from 'src/components/Select';

import { capitalizeFirstLetter, toTextCase } from 'src/helpers/text';

import useTexts from 'src/hooks/useTexts';

import { AgentFormData, AgentStatus } from 'src/types/dao/agent.types';

import AddCharacteristic from './components/AddCharacteristic';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

export type Props = {
  initialData?: AgentFormData;
  onSave?: (formData: AgentFormData) => void;
  onStart?: () => void;
  onCopy?: () => void;
  onEditClick?: () => void;
  canEdit?: boolean;
  readonly?: boolean;
  isAdmin?: boolean;
  setIsInitialDataChanged?: (value: boolean) => void;
};

const AgentEditForm: FC<Props> = ({
  initialData,
  onSave,
  onStart,
  onCopy,
  canEdit,
  onEditClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  readonly,
  setIsInitialDataChanged,
  isAdmin,
}) => {
  const {
    formData,
    formDataSetters,
    setStatus,
    newCharacteristicFormData,
    newCharacteristicFormDataSetters,
    newCharacteristicFormErrors,
    addNewCharacteristic,
    removeCharacteristic,
    changeCharacteristic,
    changeAdminSnapshot,
    changeSafetyAnalysis,
    setLongTermMemory,
    setSafetySnapshot,
    handleImprovise,
    save,
    formErrors,
    // canEdit,
    // handleEdit,
  } = useComponentProps({
    initialData,
    onSave,
    setIsInitialDataChanged,
  });

  const { t } = useTexts();

  const showAdminSnapshot = isAdmin && (!readonly || formData.admin_snapshot);
  return (
    <div className={styles['container']}>
      <div className={styles['title-section']}>
        <div className={styles['title-icons-wrapper']}>
          <h1>{t('agent.agent')}</h1>
          <div className={styles['icons']}>
            {onStart && (
              <IconButton onClick={onStart} tooltip={t('form.start')}>
                <PlayArrowRoundedIcon />
              </IconButton>
            )}
            {!readonly && (
              <IconButton
                className='agent-save-button'
                onClick={save}
                tooltip={t('form.save_all')}
              >
                <SaveRoundedIcon />
              </IconButton>
            )}
            {onCopy && (
              <IconButton onClick={onCopy} tooltip={t('form.copy')}>
                <ContentCopyRoundedIcon />
              </IconButton>
            )}
            {canEdit && (
              <IconButton onClick={onEditClick} tooltip={t('form.edit')}>
                <EditRoundedIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={styles['status-section']}>
          {(readonly && (
            <TextField
              hiddenLabel
              value={t('general.' + formData.status)}
              disabled
            />
          )) || (
            <Select
              label={t('general.public')}
              options={Object.values(AgentStatus).map((key) => ({
                value: key,
                label: t('general.' + key),
              }))}
              value={formData.status}
              onSelect={(value) => setStatus(value as AgentStatus)}
            />
          )}
        </div>
      </div>
      <div className={styles['info-section']}>
        <div className={styles['text-fields-section']}>
          {!readonly && (
            <div className={styles['button-wrapper']}>
              <Button variant='outlined' onClick={handleImprovise}>
                {t('general.improvise')}
              </Button>
            </div>
          )}
          <div
            className={styles['characteristic-row']}
            data-testid='name-input'
          >
            <TextField
              id='name-input'
              required
              label={t('form.name')}
              value={formData.name}
              onChange={formDataSetters.name}
              disabled={readonly}
              {...(initialData && {
                changed: formData.name !== initialData?.name,
              })}
              {...(!!formErrors?.name && {
                error: true,
                helperText: formErrors.name,
              })}
            />
          </div>
        </div>
        <div className={styles['agent-images']}>
          <div className={styles['agent-image-wrapper']}>
            <UploadImageVideoField
              value={formData.image_url}
              onChange={formDataSetters.image_url}
              className={styles['agent-image']}
              readonly={readonly}
              changed={formData.image_url !== initialData?.image_url}
              acceptedFileTypes='image'
              title='Agent image'
            />
          </div>
          <div className={styles['agent-image-wrapper']}>
            <UploadImageVideoField
              value={formData.thumbnail_url}
              onChange={formDataSetters.thumbnail_url}
              className={styles['agent-image']}
              readonly={readonly}
              changed={formData.thumbnail_url !== initialData?.thumbnail_url}
              acceptedFileTypes='image'
              title='Agent thumbnail'
            />
          </div>
          <div className={styles['agent-image-wrapper']}>
            <UploadImageVideoField
              value={formData.animation_url}
              onChange={formDataSetters.animation_url}
              className={styles['agent-image']}
              readonly={readonly}
              changed={formData.animation_url !== initialData?.animation_url}
              acceptedFileTypes='video'
              title='Agent animation'
            />
          </div>
          <div className={styles['agent-image-wrapper']}>
            <UploadImageVideoField
              value={formData.minecraft_skin_url}
              onChange={formDataSetters.minecraft_skin_url}
              className={styles['agent-image']}
              readonly={readonly}
              changed={
                formData.minecraft_skin_url !== initialData?.minecraft_skin_url
              }
              acceptedFileTypes='image'
              title='Agent Minecraft Skin'
            />
          </div>
        </div>
      </div>
      <div className={styles['editor-section']}>
        {Object.entries(formData.inner_state).map(([key, value]) => {
          let rowsNumber = 2;
          if (['description', 'backstory', 'back_story'].includes(key)) {
            rowsNumber = 6;
          }
          return (
            <div className={styles['characteristic-row']} key={key}>
              <TextField
                value={value as string}
                label={
                  key === 'name'
                    ? 'Minecraft name'
                    : capitalizeFirstLetter(toTextCase(key))
                }
                onChange={(val) => changeCharacteristic(key, val)}
                id='outlined-textarea'
                placeholder={capitalizeFirstLetter(toTextCase(key))}
                fullWidth
                disabled={readonly}
                multiline
                rows={rowsNumber}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    fontSize: '18px',
                    fontFamily: 'sans-serif',
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    marginBottom: '20px',
                  },
                }}
              />
              {!readonly && (
                <IconButton
                  className={styles['remove-btn']}
                  onClick={() => removeCharacteristic(key)}
                  theme='red'
                  tooltip={t('form.remove')}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              )}
            </div>
          );
        })}
        {!readonly && (
          <AddCharacteristic
            name={newCharacteristicFormData.name}
            setName={newCharacteristicFormDataSetters.name}
            value={newCharacteristicFormData.value}
            setValue={newCharacteristicFormDataSetters.value}
            onAdd={addNewCharacteristic}
            added={formData.inner_state}
            validationErrors={newCharacteristicFormErrors}
          />
        )}
      </div>
      <div className={styles['editor-section']}>
        <Accordion
          expanded
          label={t('agent.long_term_memory')}
          description={t('agent.long_term_memory_help_text')}
        >
          {() => (
            <TextField
              value={formData.memory.long_term_memory}
              onChange={setLongTermMemory}
              id='long-term-memory-input'
              placeholder={capitalizeFirstLetter(toTextCase('Backstory'))}
              fullWidth
              disabled={readonly}
              multiline
              rows={6}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  fontSize: '18px',
                  fontFamily: 'sans-serif',
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
          )}
        </Accordion>
        <Accordion
          label={t('agent.safety_analysis')}
          description={t('agent.safety_analysis_help_text')}
        >
          {() => (
            <TextField
              value={formData.safety_analysis}
              onChange={setSafetySnapshot}
              id='safety-snapshot-input'
              placeholder={capitalizeFirstLetter(toTextCase('Safety analyses'))}
              fullWidth
              disabled={readonly}
              multiline
              rows={6}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  fontSize: '18px',
                  fontFamily: 'sans-serif',
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
          )}
        </Accordion>
      </div>
      <div
        className={classNames(
          styles['editor-section'],
          styles['advanced-section'],
        )}
      >
        <div className={styles['memory-row']}>
          <Accordion label={t('general.advanced')}>
            {() => (
              <TextField
                value={formData.advanced}
                onChange={formDataSetters?.advanced}
                id='outlined-textarea'
                placeholder={capitalizeFirstLetter(toTextCase('Advanced'))}
                fullWidth
                disabled={readonly}
                multiline
                rows={2}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    fontSize: '18px',
                    fontFamily: 'sans-serif',
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    marginBottom: '20px',
                  },
                }}
              />
            )}
          </Accordion>
        </div>
        {showAdminSnapshot && (
          <div className={styles['text-area-container']}>
            <Typography
              variant='h5'
              sx={{ marginBottom: '10px', fontWeight: '700' }}
            >
              {'Admin snapshot'}
            </Typography>
            <TextField
              hiddenLabel
              multiline
              value={formData.admin_snapshot}
              onChange={(val) => changeAdminSnapshot(val)}
              fullWidth
              sx={{
                marginBottom: '20px',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentEditForm;
