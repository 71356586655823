import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Checkbox } from '@mui/material';
import React from 'react';

import IconButton from 'src/components/Buttons/IconButton';
import DotsActions from 'src/components/TableList/components/DotsActions';

import { Agent } from 'src/types/dao/agent.types';

import { AgentTableActionConfig } from './index';
import styles from './styles.module.scss';

const defaultImage = require('src/assets/images/default_game_display.jpg');

export const renderAgentCell = (
  agent: Agent,
  tableActionConfig?: AgentTableActionConfig,
  selectedAgents?: Array<string>,
  setSelectedAgents?: React.Dispatch<React.SetStateAction<Array<string>>>,
) => {
  const isChecked = selectedAgents?.includes(agent.id || '');
  const handleCheckboxChange = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAgents?.((prev) => {
      const newArray = [...prev];
      const index = newArray.indexOf(agent.id || '');
      if (index > -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(agent.id || '');
      }
      return newArray;
    });
  };

  return {
    select: <Checkbox checked={isChecked} onClick={handleCheckboxChange} />,
    name: (
      <div className={styles['agents-name-sell']}>
        <img
          src={agent.thumbnail_url || defaultImage}
          alt='avatar'
          className={styles['agent-avatar']}
        />
        {agent.name}
      </div>
    ),
    ownerName: agent.owner_name,
    ownerAccess: agent.owner_access,
    status: agent.status,
    totalPlayTime: agent.total_play_time,
    totalPlaySessions: agent.total_play_sessions,
    totalPlayers: agent.total_players,
    createdAt: agent.created_at
      ? new Date(agent.created_at).toLocaleString()
      : null,
    lastUpdatedTime: agent.last_updated_time
      ? new Date(agent.last_updated_time).toLocaleString()
      : null,
    edit: (
      <div className={styles['action-item']}>
        <IconButton
          onClick={
            tableActionConfig
              ? (event) => tableActionConfig.quickEditingClick(agent.id, event)
              : () => {} // eslint-disable-line @typescript-eslint/no-empty-function
          }
          tooltip={tableActionConfig && tableActionConfig.t('form.edit')}
        >
          <EditRoundedIcon />
        </IconButton>
      </div>
    ),
    view: (
      <div className={styles['action-item']}>
        <IconButton
          tooltip={tableActionConfig?.t('agent.show_view')}
          className={styles['action']}
          onClick={(event) =>
            tableActionConfig?.navigateToAgent(agent.id || '', event)
          }
        >
          <VisibilityIcon />
        </IconButton>
      </div>
    ),
    actions: (
      <DotsActions>
        {tableActionConfig?.canEdit && (
          <div className={styles['action-item']}>
            <IconButton
              onClick={tableActionConfig.handleEdit}
              tooltip={tableActionConfig.t('form.edit')}
            >
              <EditRoundedIcon />
            </IconButton>
          </div>
        )}
        {tableActionConfig?.canCopy && (
          <div className={styles['action-item']}>
            <IconButton
              onClick={tableActionConfig.handleCopy}
              tooltip={tableActionConfig.t('form.copy')}
            >
              <ContentCopyRoundedIcon />
            </IconButton>
          </div>
        )}
        {tableActionConfig?.canArchive && (
          <div className={styles['action-item']}>
            <IconButton
              onClick={() => tableActionConfig.handleArchive()}
              theme='red'
              className={styles['archive-button']}
              tooltip={tableActionConfig.t('form.archive')}
            >
              <ArchiveRoundedIcon />
            </IconButton>
          </div>
        )}
      </DotsActions>
    ),
  };
};

export const tableHeaders = [
  'Select',
  'Name',
  'Owner Name',
  'Owner Access',
  'Status',
  'Total Play Time',
  'Total Play Sessions',
  'Total Players',
  'Created At',
  'Last Updated',
  'Edit',
  'View',
  'Actions',
];
