import { api } from 'common-ui';
import { URLS } from 'common-ui';

export const makeApiStopGameInstance =
  () =>
  ({ gameInstanceId }: { gameInstanceId: string }) =>
    makeApiStopGame(gameInstanceId);

export const makeApiStopGameInstanceAsync = async ({
  gameInstanceId,
}: {
  gameInstanceId: string;
}) => {
  try {
    return await makeApiStopGame(gameInstanceId);
  } catch (error) {
    console.error('Error stopping game instance', error);
    return false;
  }
};

const makeApiStopGame = (gameInstanceId: string) =>
  api.get<void>(URLS.game.admin.stopGameInstance, {
    params: {
      game_instance_id: gameInstanceId,
    },
  });
