import { useMemo } from 'react';

import {
  makeApiAdminAddMinutesToUser,
  makeApiAdminUpdateUser,
} from 'src/api/user/functions/makeApiAdminManage';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';

export const useApiUpdateUser = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiAdminUpdateUser(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};

export const useApiAddMinutesToUser = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiAdminAddMinutesToUser(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};
