import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';

import DatePickerComponent from 'src/components/DatePickerComponent';
import Search from 'src/components/Search';

import useTexts from 'src/hooks/useTexts';

import BasicStatistics from './components/BasicStatistics';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export type GameInstancesProps = {
  additionalClassName?: string;
  customLoader?: React.ReactNode;
  fromAdminUser?: boolean;
  startTimeFromAdmin?: Dayjs;
  endTimeFromAdmin?: Dayjs;
  filters?: {
    userId?: string;
  };
};

type TextGetter = (key: string) => string | undefined;

export type GameTableActionConfig = {
  redirectToGame: (gameId: string) => void;
  stopGame?: (gameId: string) => void;
  downloadInstanceLog: (gameId: string) => void;
  copyGameInstanceId: (gameId: string) => void;
  t: TextGetter;
};

const CompletedGameInstances: FC<GameInstancesProps> = ({
  additionalClassName,
  filters,
  fromAdminUser,
  startTimeFromAdmin,
  endTimeFromAdmin,
}) => {
  const { t } = useTexts();
  const {
    search,
    setSearch,
    searchOpen,
    setSearchOpen,
    completedGameInstances,
    users,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    redirectToGame,
    downloadInstanceLog,
    copyGameInstanceId,
    loadNextCompletedPage,
    loadPrevCompletedPage,
    completedGamePage,
    canLoadNextCompleted,
    canLoadPrevCompleted,
  } = useComponentProps({ ...filters });

  useEffect(() => {
    if (startTimeFromAdmin && endTimeFromAdmin) {
      setStartDate(startTimeFromAdmin);
      setEndDate(endTimeFromAdmin);
    }
  }, [startTimeFromAdmin, endTimeFromAdmin, setStartDate, setEndDate]);

  const [stats, setStats] = useState({
    totalPlayTime: 0,
    totalPlaySessions: 0,
    uniquePlayers: 0,
    avgPlaySessionLength: 0,
    avgPlaySessionLengthAll: 0,
  });
  const [topUsers, setTopUsers] = useState<{ name: string; time: number }[]>(
    [],
  );
  const [sessionLengthHistogram, setSessionLengthHistogram] = useState<
    number[]
  >([]);

  const calculateStats = useCallback(() => {
    let totalTime = 0;
    let validSessions = 0;
    let allSessionsTime = 0;
    const uniquePlayerIds = new Set<string>();

    completedGameInstances.forEach((game) => {
      const start = new Date(game.start_time).getTime();
      const end = new Date(game.end_time).getTime();
      const duration = (end - start) / 1000; // in seconds

      allSessionsTime += duration;

      if (duration >= 1) {
        totalTime += duration;
        validSessions++;
        uniquePlayerIds.add(game.initiator_id);
      }
    });

    setStats((prevStats) => {
      const newStats = {
        totalPlayTime: totalTime,
        totalPlaySessions: completedGameInstances.length,
        uniquePlayers: uniquePlayerIds.size,
        avgPlaySessionLength: validSessions > 0 ? totalTime / validSessions : 0,
        avgPlaySessionLengthAll:
          completedGameInstances.length > 0
            ? allSessionsTime / completedGameInstances.length
            : 0,
      };

      if (JSON.stringify(prevStats) === JSON.stringify(newStats)) {
        return prevStats; // No update needed
      }

      return newStats;
    });
  }, [completedGameInstances]);

  const calculateTopUsers = useCallback(() => {
    const hostPlayTime: { [key: string]: number } = {};

    completedGameInstances.forEach((game) => {
      const start = new Date(game.start_time).getTime();
      const end = new Date(game.end_time).getTime();
      const duration = (end - start) / 1000; // in seconds

      const initiator = users[game.initiator_id]?.name || 'Unknown';
      if (hostPlayTime[initiator]) {
        hostPlayTime[initiator] += duration;
      } else {
        hostPlayTime[initiator] = duration;
      }
    });

    const sortedHosts = Object.entries(hostPlayTime)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 20)
      .map(([name, time]) => ({ name, time: time / 60 })); // Convert to minutes

    setTopUsers((prevTopUsers) => {
      if (JSON.stringify(prevTopUsers) === JSON.stringify(sortedHosts)) {
        return prevTopUsers; // No update needed
      }

      return sortedHosts;
    });
  }, [completedGameInstances, users]);

  const calculateSessionLengthHistogram = useCallback(() => {
    const histogram = new Array(61).fill(0); // 0-59 minutes, plus 60+ bucket

    completedGameInstances.forEach((game) => {
      const start = new Date(game.start_time).getTime();
      const end = new Date(game.end_time).getTime();
      const durationMinutes = Math.floor((end - start) / (1000 * 60));

      if (durationMinutes >= 60) {
        histogram[60]++;
      } else {
        histogram[durationMinutes]++;
      }
    });

    setSessionLengthHistogram((prevHistogram) => {
      if (JSON.stringify(prevHistogram) === JSON.stringify(histogram)) {
        return prevHistogram; // No update needed
      }

      return histogram;
    });
  }, [completedGameInstances]);

  useEffect(() => {
    calculateStats();
    calculateTopUsers();
    calculateSessionLengthHistogram();
  }, [
    completedGameInstances,
    users,
    calculateStats,
    calculateTopUsers,
    calculateSessionLengthHistogram,
  ]);

  const basicStatisticsActions = {
    redirectToGame,
    downloadInstanceLog,
    copyGameInstanceId,
  };

  const paginationConfig = {
    loadNextCompletedPage,
    loadPrevCompletedPage,
    completedGamePage,
    canLoadNextCompleted,
    canLoadPrevCompleted,
  };

  return (
    <div className={additionalClassName}>
      <div className={styles['info-group']}>
        <div className={styles['info-search']}>
          <Search
            value={search}
            onChange={setSearch}
            searchOpen={searchOpen}
            setSearchOpen={(searchOpen: boolean) => {
              setSearchOpen(searchOpen);
              if (!searchOpen) {
                setSearch('');
              }
            }}
            iconClassName={styles['info-search-icon']}
          />
        </div>
        <div className={styles['info-title-container']}>
          <h2>{t('game.game_instances')}:</h2>
        </div>
        <div
          className={classNames(styles['agents-wrapper'], {
            [styles['search-open']]: searchOpen,
          })}
        >
          <div className={styles['filter-wrapper']}>
            <div className={styles['filters-date-picker']}>
              {!fromAdminUser && (
                <DatePickerComponent
                  start={startDate}
                  end={endDate}
                  setStart={setStartDate}
                  setEnd={setEndDate}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles['running-games-wrapper'], {})}>
        <BasicStatistics
          completedGameInstances={completedGameInstances}
          sessionLengthHistogram={sessionLengthHistogram}
          stats={stats}
          topUsers={topUsers}
          users={users}
          tableActions={basicStatisticsActions}
          paginationConfig={paginationConfig}
        />
      </div>
    </div>
  );
};

export default CompletedGameInstances;
