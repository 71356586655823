import { getTierWeight, User, UserTier } from 'common-ui';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useApiAdminGetGameInstanceLog,
  useApiAdminGetGameInstances,
} from 'src/api/game/hooks/useApiAdminGet';
import { useApiStopGameInstance } from 'src/api/game/hooks/useApiManage';
import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';

import { ADMIN_GAME_INSTANCE_ROUTE } from 'src/constants/routes';

import { dateToUnixTimestamp } from 'src/helpers/datetime';

import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import {
  AdminGetGameInstancesResponse,
  GetDownloadLinkResponse,
} from 'src/types/api/game.types';
import { GameInstance } from 'src/types/dao/game.types';

type Props = {
  userId?: string;
};

const CHUNK_SIZE = 50;
const INVALID_ID = '00000000-0000-0000-0000-000000000000';

const useComponentProps = ({ userId }: Props) => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [completedGameInstances, setCompletedGameInstances] = useState<
    Record<string, GameInstance>
  >({});
  const [completedGamePage, setCompletedGamePage] = useState<number>(1);
  const [completedGameHasNext, setCompletedGameHasNext] =
    useState<boolean>(true);
  const [stopModalOpen, setStopModalOpen] = useState<boolean>(false);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const now = new Date();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(sevenDaysAgo));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(now));
  const [users, setUsers] = useState<Record<string, User>>({});
  const currentUser = useAppSelector(getCurrentUser);
  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);
  const [activeGameId, setActiveGameId] = useState<string>('');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTexts();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const {
    doRequest: adminGetGameInstances,
    isLoading: isGameInstancesLoading,
  } = useApiAdminGetGameInstances({
    onResponse(response: AdminGetGameInstancesResponse) {
      if (response?.data) {
        const newCompletedInstances = response.data.filter(
          (instance) => instance.end_time,
        );

        if (newCompletedInstances.length) {
          setCompletedGameInstances((prev) => ({
            ...prev,
            ...newCompletedInstances.reduce(
              (acc: { [x: string]: GameInstance }, curr: GameInstance) => {
                acc[curr.id] = curr;
                return acc;
              },
              {} as Record<string, GameInstance>,
            ),
          }));
          setCompletedGamePage(response.pagination.current_page);
          setCompletedGameHasNext(response.pagination.having_next_page);
        }
      }
    },
  });

  const reloadInstances = useCallback(() => {
    setCompletedGameInstances({});

    if (userId) {
      adminGetGameInstances({
        isRunning: false,
        initiator: userId,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
        search: debouncedSearch,
      });
    } else {
      adminGetGameInstances({
        isRunning: false,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
        search: debouncedSearch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, startDate, endDate, debouncedSearch]);

  const { doRequest: stopGameInstance, isLoading: isStopGameInstanceLoading } =
    useApiStopGameInstance({
      onSuccess: useCallback(() => {
        setStopModalOpen(false);
        reloadInstances();
      }, [reloadInstances]),
    });

  const { doRequest: downloadLog } = useApiAdminGetGameInstanceLog({
    onResponse: useCallback((response: GetDownloadLinkResponse) => {
      window.open(response.download_url, '_blank');
    }, []),
  });

  const { doRequest: getUsersByIds, isLoading: isCurrentUserLoading } =
    useApiAdminGetUsers({
      onResponse(response) {
        setUsers((prev) => ({
          ...prev,
          ...response.data.reduce(
            (acc: { [x: string]: User }, curr: User) => {
              acc[curr.id] = curr;
              return acc;
            },
            {} as Record<string, User>,
          ),
        }));
      },
    });

  const chunk = (arr: string[], size: number) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };

  const userIds = useMemo<Set<string>>(() => {
    const ids = new Set<string>();
    Object.values(completedGameInstances).forEach((instance) => {
      ids.add(instance.initiator_id);
    });
    return ids;
  }, [completedGameInstances]);

  const missingUsers = useMemo(() => {
    const missing = new Set<string>();
    userIds.forEach((id) => {
      if (!users[id] && id !== INVALID_ID) {
        missing.add(id);
      }
    });
    return missing;
  }, [userIds, users]);

  useEffect(() => {
    if (
      missingUsers.size > 0 &&
      userAccessTier >= getTierWeight(UserTier.ADMIN)
    ) {
      const idChunks = chunk(Array.from(missingUsers), CHUNK_SIZE);
      const getChunkUsers = (ids: string[]) => {
        getUsersByIds({ ids });
      };
      idChunks.forEach(getChunkUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingUsers, userAccessTier]);

  useEffect(() => {
    // Reload on filter change
    reloadInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, userId, debouncedSearch]);

  const redirectToGame = (id: string) => {
    history.push(`${ADMIN_GAME_INSTANCE_ROUTE}/${id}`);
  };

  const stopGame = (gameId: string) => {
    setStopModalOpen(true);
    setActiveGameId(gameId);
  };

  const downloadInstanceLog = (gameId: string) => {
    setActiveGameId(gameId);
    downloadLog({ instanceId: gameId });
  };

  const copyGameInstanceId = (gameId: string) => {
    setActiveGameId(gameId);
    navigator.clipboard.writeText(gameId);
    enqueueSnackbar(t('general.copied'), { variant: 'success' });
  };

  const loadNextCompletedPage = () => {
    if (completedGameHasNext) {
      setCompletedGameInstances({});

      adminGetGameInstances({
        isRunning: false,
        initiator: userId,
        page: completedGamePage + 1,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
        search: debouncedSearch,
      });
      setCompletedGamePage((prevPage) => prevPage + 1);
    }
  };

  const loadPrevCompletedPage = () => {
    if (completedGamePage > 1) {
      setCompletedGameInstances({});

      adminGetGameInstances({
        isRunning: false,
        initiator: userId,
        page: completedGamePage - 1,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
        search: debouncedSearch,
      });
      setCompletedGamePage((prevPage) => prevPage - 1);
    }
  };

  // Determine if next or previous pages can be loaded
  const canLoadNextCompleted = useMemo(
    () => completedGameHasNext,
    [completedGameHasNext],
  );
  const canLoadPrevCompleted = useMemo(
    () => completedGamePage > 1,
    [completedGamePage],
  );

  return {
    search,
    setSearch,
    searchOpen,
    setSearchOpen,
    completedGameInstances: Object.values(completedGameInstances),
    users,
    isLoading:
      isGameInstancesLoading ||
      isCurrentUserLoading ||
      isStopGameInstanceLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    reloadInstances,
    stopGameInstance,
    stopModalOpen,
    setStopModalOpen,
    downloadLog,
    redirectToGame,
    stopGame,
    downloadInstanceLog,
    copyGameInstanceId,
    activeGameId,
    loadNextCompletedPage,
    loadPrevCompletedPage,
    completedGamePage,
    canLoadNextCompleted,
    canLoadPrevCompleted,
  };
};

export default useComponentProps;
