import { getTierWeight, User, UserTier } from 'common-ui';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeApiStopGameInstanceAsync } from 'src/api/game/functions/makeApiManage';
import {
  useApiAdminGetGameInstanceLog,
  useApiAdminGetGameInstances,
} from 'src/api/game/hooks/useApiAdminGet';
import { useApiStopGameInstance } from 'src/api/game/hooks/useApiManage';
import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';

import { ADMIN_GAME_INSTANCE_ROUTE } from 'src/constants/routes';

import { dateToUnixTimestamp } from 'src/helpers/datetime';

import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import {
  AdminGetGameInstancesResponse,
  GetDownloadLinkResponse,
} from 'src/types/api/game.types';
import { GameInstance } from 'src/types/dao/game.types';

type Props = {
  userId?: string;
};

const CHUNK_SIZE = 50;
const INVALID_ID = '00000000-0000-0000-0000-000000000000';

const useComponentProps = ({ userId }: Props) => {
  const [runningGameInstances, setRunningGameInstances] = useState<
    Record<string, GameInstance>
  >({});
  const [selectedInstances, setSelectedInstances] = useState<Array<string>>([]);
  const [isLoadingBatchStop, setIsLoadingBatchStop] = useState<boolean>(false);
  const [batchStopModalOpen, setBatchStopModalOpen] = useState<boolean>(false);
  const [runningGamePage, setRunningGamePage] = useState<number>(1);
  const [runningGameHasNext, setRunningGameHasNext] = useState<boolean>(true);
  const [stopModalOpen, setStopModalOpen] = useState<boolean>(false);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const now = new Date();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(sevenDaysAgo));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(now));
  const [users, setUsers] = useState<Record<string, User>>({});
  const currentUser = useAppSelector(getCurrentUser);
  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);
  const [activeGameId, setActiveGameId] = useState<string>('');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTexts();

  const {
    doRequest: adminGetGameInstances,
    isLoading: isGameInstancesLoading,
  } = useApiAdminGetGameInstances({
    onResponse(response: AdminGetGameInstancesResponse) {
      if (response?.data) {
        const newRunningInstances = response.data.filter(
          (instance) => !instance.end_time,
        );

        if (newRunningInstances.length) {
          setRunningGameInstances((prev) => ({
            ...prev,
            ...newRunningInstances.reduce(
              (acc: { [x: string]: GameInstance }, curr: GameInstance) => {
                acc[curr.id] = curr;
                return acc;
              },
              {} as Record<string, GameInstance>,
            ),
          }));
          setRunningGamePage(response.pagination.current_page);
          setRunningGameHasNext(response.pagination.having_next_page);
        }
      }
    },
  });

  const reloadInstances = useCallback(() => {
    setRunningGameInstances({});

    if (userId) {
      adminGetGameInstances({
        isRunning: true,
        initiator: userId,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
    } else {
      adminGetGameInstances({
        isRunning: true,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
    }
    setRunningGamePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, startDate, endDate]);

  const { doRequest: stopGameInstance, isLoading: isStopGameInstanceLoading } =
    useApiStopGameInstance({
      onSuccess: useCallback(() => {
        setStopModalOpen(false);
        reloadInstances();
      }, [reloadInstances]),
    });

  const { doRequest: downloadLog } = useApiAdminGetGameInstanceLog({
    onResponse: useCallback((response: GetDownloadLinkResponse) => {
      window.open(response.download_url, '_blank');
    }, []),
  });

  const { doRequest: getUsersByIds, isLoading: isCurrentUserLoading } =
    useApiAdminGetUsers({
      onResponse(response) {
        setUsers((prev) => ({
          ...prev,
          ...response.data.reduce(
            (acc: { [x: string]: User }, curr: User) => {
              acc[curr.id] = curr;
              return acc;
            },
            {} as Record<string, User>,
          ),
        }));
      },
    });

  const chunk = (arr: string[], size: number) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };

  const userIds = useMemo<Set<string>>(() => {
    const ids = new Set<string>();
    Object.values(runningGameInstances).forEach((instance) => {
      ids.add(instance.initiator_id);
    });
    return ids;
  }, [runningGameInstances]);

  const missingUsers = useMemo(() => {
    const missing = new Set<string>();
    userIds.forEach((id) => {
      if (!users[id] && id !== INVALID_ID) {
        missing.add(id);
      }
    });
    return missing;
  }, [userIds, users]);

  useEffect(() => {
    if (
      missingUsers.size > 0 &&
      userAccessTier >= getTierWeight(UserTier.ADMIN)
    ) {
      const idChunks = chunk(Array.from(missingUsers), CHUNK_SIZE);
      const getChunkUsers = (ids: string[]) => {
        getUsersByIds({ ids });
      };
      idChunks.forEach(getChunkUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingUsers, userAccessTier]);

  useEffect(() => {
    if (userId) {
      adminGetGameInstances({
        isRunning: true,
        initiator: userId,
        page: runningGamePage,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
    } else {
      adminGetGameInstances({
        isRunning: true,
        page: runningGamePage,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningGamePage, startDate, endDate, userId]);

  useEffect(() => {
    // Reload on filter change
    reloadInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const redirectToGame = (id: string) => {
    history.push(`${ADMIN_GAME_INSTANCE_ROUTE}/${id}`);
  };

  const stopGame = (gameId: string) => {
    setStopModalOpen(true);
    setActiveGameId(gameId);
  };

  const downloadInstanceLog = (gameId: string) => {
    setActiveGameId(gameId);
    downloadLog({ instanceId: gameId });
  };

  const copyGameInstanceId = (gameId: string) => {
    setActiveGameId(gameId);
    navigator.clipboard.writeText(gameId);
    enqueueSnackbar(t('general.copied'), { variant: 'success' });
  };

  const loadNextRunningPage = () => {
    if (runningGameHasNext) {
      setRunningGameInstances({});

      adminGetGameInstances({
        isRunning: true,
        initiator: userId,
        page: runningGamePage + 1,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
      setRunningGamePage((prevPage) => prevPage + 1);
    }
  };

  const loadPrevRunningPage = () => {
    if (runningGamePage > 1) {
      setRunningGameInstances({});

      adminGetGameInstances({
        isRunning: true,
        initiator: userId,
        page: runningGamePage - 1,
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      });
      setRunningGamePage((prevPage) => prevPage - 1);
    }
  };

  // Determine if next or previous pages can be loaded
  const canLoadNextRunning = useMemo(
    () => runningGameHasNext,
    [runningGameHasNext],
  );
  const canLoadPrevRunning = useMemo(
    () => runningGamePage > 1,
    [runningGamePage],
  );

  const isLoading =
    isGameInstancesLoading ||
    isCurrentUserLoading ||
    isStopGameInstanceLoading ||
    isLoadingBatchStop;
  const isDisabledBatchStop = !selectedInstances.length || isLoading;

  const handleBatchStop = async () => {
    if (isDisabledBatchStop) return;
    setIsLoadingBatchStop(true);
    for (const gameId of selectedInstances) {
      await makeApiStopGameInstanceAsync({ gameInstanceId: gameId });
      await new Promise((resolve) => setTimeout(resolve, 250));
    }
    setSelectedInstances([]);
    reloadInstances();
    setIsLoadingBatchStop(false);
    setBatchStopModalOpen(false);
  };

  const confirmModalHandler = async () => {
    if (batchStopModalOpen) {
      await handleBatchStop();
    } else {
      stopGameInstance({ gameInstanceId: activeGameId });
    }
  };

  return {
    runningGameInstances: Object.values(runningGameInstances),
    users,
    isLoading,
    isDisabledBatchStop,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    reloadInstances,
    stopGameInstance,
    stopModalOpen,
    setStopModalOpen,
    downloadLog,
    redirectToGame,
    stopGame,
    downloadInstanceLog,
    copyGameInstanceId,
    activeGameId,
    loadNextRunningPage,
    loadPrevRunningPage,
    runningGamePage,
    canLoadNextRunning,
    canLoadPrevRunning,
    selectedInstances,
    isLoadingBatchStop,
    setBatchStopModalOpen,
    setSelectedInstances,
    batchStopModalOpen,
    confirmModalHandler,
  };
};

export default useComponentProps;
