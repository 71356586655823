import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';

import Loader from 'src/components/Loader';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import UserPanel from 'src/components/SidePanels/SidePanel/FeaturedSidePanel/UserPanel';
import TableList from 'src/components/TableList';

import { ADMIN_USERS_ROUTE } from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

import { useAppDispatch } from 'src/store/hooks';
import { setSelectedUserInstance } from 'src/store/reducers/userSlice';

import { User } from 'src/types/dao/user.types';

import { renderUserCell, tableHeaders } from './common';
import styles from './styles.module.scss';

export type Props = {
  data: Array<User>;
  className?: string;
  isLoading?: boolean;
  paginationConfig: {
    loadNext: () => void;
    loadPrev: () => void;
    pageNumber: number;
    canLoadNext: boolean;
    canLoadPrev: boolean;
  };
  navigateToUser: (agentId: string, event: React.MouseEvent) => void;
};

type TextGetter = (key: string) => string | undefined;

export type UserTableActionConfig = {
  navigateToUser: (agentId: string, event: React.MouseEvent) => void;
  t: TextGetter;
};
const UsersList: FC<Props> = ({
  data,
  isLoading,
  paginationConfig,
  navigateToUser,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTexts();
  const history = useHistory();
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const [userByQueryId, setUserByQueryId] = useState<User>();

  const {
    doRequest: getUserById,
    isLoading: isCurrentUserLoading,
    responseData: singleUser,
  } = useApiAdminGetUsers({
    onResponse(response) {
      if (response.data) {
        const [currentUser] = response.data;
        setUserByQueryId(currentUser);
        dispatch(setSelectedUserInstance(currentUser));
      }
    },
  });

  const sortedData = data
    .slice()
    .sort(
      (a, b) =>
        new Date(b.last_login).getTime() - new Date(a.last_login).getTime(),
    );

  const onCloseSidePanel = () => {
    setShowSidePanel(false);
    dispatch(setSelectedUserInstance(null));
    history.push(ADMIN_USERS_ROUTE);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const userId = path.split('/').pop();

    if (userId && path.split('/').length > 2) {
      const user = data.find((user) => user.id === userId);
      if (user) {
        dispatch(setSelectedUserInstance(user));
        setShowSidePanel(true);
      } else {
        loadUserById(userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, history]);

  const loadUserById = async (userId: string) => {
    try {
      await getUserById({ ids: [userId] });
      if (userByQueryId) {
        dispatch(setSelectedUserInstance(userByQueryId));
        setShowSidePanel(true);
      }
    } catch (error) {
      console.error(error);
      history.push(ADMIN_USERS_ROUTE);
    }
  };

  const tableActionConfig: UserTableActionConfig = {
    navigateToUser,
    t,
  };

  const nothingToShow = sortedData.length === 0;

  return (
    <>
      {isLoading ? (
        <div className={styles['loader-wrapper']}>
          <Loader absolute backdrop />
        </div>
      ) : (
        <div className='table-wrapper'>
          <TableList<User, UserTableActionConfig>
            data={sortedData}
            headers={tableHeaders}
            renderCell={renderUserCell}
            tableActionConfig={tableActionConfig}
          />
          <div className='table-wrapper_pagination'>
            {nothingToShow ? (
              <NothingToShow />
            ) : (
              <Pagination
                loadNextPage={paginationConfig.loadNext}
                loadPrevPage={paginationConfig.loadPrev}
                pageNumber={paginationConfig.pageNumber}
                canLoadNext={paginationConfig.canLoadNext}
                canLoadPrev={paginationConfig.canLoadPrev}
              />
            )}
          </div>
        </div>
      )}
      <UserPanel onPanelClose={onCloseSidePanel} isOpen={showSidePanel} />
    </>
  );
};

export default UsersList;
