import { api, URLS } from 'common-ui';

import { GetGroupAgentsResponse } from 'src/types/api/agent.types';
import { GetAgentSuccessResponse } from 'src/types/api/api.types';
import {
  Agent,
  AgentFormData,
  InstanceResponse,
} from 'src/types/dao/agent.types';

import { convertFormDataToRequestData } from '../converters/formData';

export const makeApiCreateAgent =
  () =>
  ({ formData }: { formData: AgentFormData }) =>
    api.post<GetAgentSuccessResponse>(
      URLS.agent.admin.create,
      convertFormDataToRequestData(formData),
    );

export const makeApiUpdateAgent =
  () =>
  ({ agentId, formData }: { agentId: string; formData: AgentFormData }) =>
    api.post<void>(
      URLS.agent.admin.update,
      convertFormDataToRequestData(formData),
      {
        params: { agent_id: agentId },
      },
    );

export const makeApiUpdateAgentStatus =
  () =>
  ({
    agentInstanceId,
    agentInstance,
  }: {
    agentInstanceId: string;
    agentInstance: InstanceResponse;
  }) =>
    api.post<void>(URLS.agent.admin.updateStatus, agentInstance, {
      params: { agent_instance_id: agentInstanceId },
    });
export const makeApiBulkCreateAgents =
  () =>
  ({ agents }: { agents: Agent[] }) =>
    api.post<GetGroupAgentsResponse>(URLS.agent.bulk, agents);

export const makeApiCopyAgent =
  () =>
  ({ agentId }: { agentId: string }) =>
    api.get<void>(URLS.agent.admin.copy, {
      params: { agent_id: agentId },
    });

export const makeApiArchiveAgent =
  () =>
  ({ agentId }: { agentId: string }) =>
    api.get<void>(URLS.agent.admin.archive, { params: { agent_id: agentId } });
