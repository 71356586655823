import { api } from 'common-ui';
import { URLS } from 'common-ui';

import {
  AdminGetGameInstancesResponse,
  GetDownloadLinkResponse,
} from 'src/types/api/game.types';
import { GameInstance } from 'src/types/dao/game.types';

type GetGameInstancesProps = {
  page?: number;
  perPage?: number;
  filters?: {
    isRunning?: boolean;
    initiator?: string;
    ids?: string[];
    from_time?: number;
    to_time?: number;
    search?: string;
  };
};

export const makeApiAdminGetGameInstances =
  () =>
  ({ page, perPage, filters }: GetGameInstancesProps = {}) =>
    api.get<AdminGetGameInstancesResponse>(URLS.game.admin.getGameInstances, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });

export const makeApiAdminGetGameInstanceLog =
  () =>
  ({ instanceId }: { instanceId: string }) =>
    api.get<GetDownloadLinkResponse>(URLS.game.admin.getGameInstanceLog, {
      params: {
        game_instance_id: instanceId,
      },
    });

export const makeApiAdminAgentSummon =
  () =>
  async ({
    agent_proto_id,
    agent_checkpoint_id,
  }: {
    agent_proto_id?: string;
    agent_checkpoint_id?: string;
  }) => {
    // Construct the AgentSummonRequest based on the provided parameters
    const requestData = {
      agent_proto_ids: agent_proto_id ? [agent_proto_id] : [],
      agent_checkpoint_ids: agent_checkpoint_id ? [agent_checkpoint_id] : [],
    };

    return api.post<GameInstance>(URLS.agent.admin.summon, requestData);
  };
