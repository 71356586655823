import { api } from 'common-ui';
import { URLS } from 'common-ui';
import { UpdateUserForm } from 'common-ui';

export const makeApiAdminUpdateUser =
  () =>
  ({ formData }: { formData: UpdateUserForm }) =>
    api.post<void>(URLS.user.admin.updateUser, formData);

export const makeApiAdminAddMinutesToUser =
  () =>
  ({ minutes, userId }: { minutes: number; userId: string }) =>
    api.post<void>(URLS.user.admin.addMinutes, { minutes, user_id: userId });
